export default {
  namespaced: true,
  state: {
    settings_info: {
      'difficulty_level': {
        0: 'apps_levels.apprentice',
        1: 'apps_levels.intermediate',
        2: 'apps_levels.advanced',
        3: 'apps_levels.expert',
      },
      'no_rush': {
        0: 'customize.deactive',
        1: 'customize.active',
      },
      'reading_mode': {
        0: 'customize.read',
        1: 'customize.listen',
        2: 'customize.read_listen',
        3: 'customize.pictograms',
      },
      'font_type': {
        0: 'customize.standard',
        1: 'customize.linked',
        2: 'customize.capital',
        3: 'customize.dyslexic'
      },
      'accessibility_settings': {
        1: 'customize.pagination',
        2: 'customize.highlighting',
        3: 'customize.speech_synthesis',
        4: 'customize.speech_recognition',
        5: 'customize.subtitles',
        6: 'customize.high_contrast',
        7: 'customize.animations_disabled',
      },
      'world_visualization': {
        1: 'world_visualization.space',
        2: 'world_visualization.islands',
        3: 'world_visualization.bigSpecial',
        4: 'world_visualization.smallSpecial',
        5: 'world_visualization.pixel3D',
        6: 'world_visualization.small',
        7: 'world_visualization.youth',
        8: 'world_visualization.highcontrast',
      },
      'language':{
        1: 'unique'
      }
    },
  },
  getters: {
    getSettings: state => {
      const { settings_info } = state
      return settings_info
    },
    getSettingsByNee: (state) => (nee_name, birthdate) => {
      console.log({nee_name, birthdate})
      const getAge = birthDate => Math.floor((new Date() - new Date(birthDate).getTime()) / 3.15576e+10)

      let age = getAge(birthdate)
      
      let wv = [1];
      if (nee_name == null) {
        //no NEE
        if (age <= 7) {
          wv = [2]
        } else if (age >= 10) {
          wv = [5]
        }
      } else {
        if (age > 7) {          
          wv = [3]
        } else {
          wv = [4]
        }
      }
      let settings = {}

      switch (nee_name) {
        case 1:
        case "attention_deficit_hyperactivity_disorder":  // Reduir las distractions
          settings['reading_mode'] = [2]
          settings['font_type'] = age <= 4 ? 2 : '0'
          settings['no_rush'] = [0]
          settings['accessibility_settings'] = [7]
          settings['world_visualization'] = wv
          break;
        case 2:
        case "autism_spectrum_disorder": // Simplificar la información
          settings['language'] = 1
          settings['reading_mode'] = [3]
          settings['font_type'] = [2]
          settings['no_rush'] = [1]
          settings['accessibility_settings'] = [7]
          settings['world_visualization'] = wv
          break;
        // case 3:
        // case "intellectual_disability": 
        //   settings['language'] = 1
        //   settings['world_visualization'] = wv
        //   settings['reading_mode'] = [3]
        //   settings['font_type'] = [2]
        //   settings['no_rush'] = [1]
        //   settings['accessibility_settings'] = [1, 2] //[1,2,3]
        //   break;
        case 4:
        case "motor_impairment": // Facilitar la interacción
          settings['language'] = 1
          settings['reading_mode'] = [2]
          settings['font_type'] = [2]
          settings['no_rush'] = [1]
          settings['accessibility_settings'] = [1]
          settings['world_visualization'] = wv
          break;
        case 5:
        case "hearing_impairment": // Disminuir información auditiva
          settings['language'] = 1
          settings['reading_mode'] = [0]
          settings['font_type'] = age <= 4 ? 2 : '0'
          settings['no_rush'] = [1]
          settings['accessibility_settings'] = [5, 7]
          break;
        case 6:
        case "visual_impairment": // Facilitar la percepción visual
          settings['reading_mode'] = [1]
          settings['font_type'] = age <= 4 ? 2 : '0'
          settings['no_rush'] = [1]
          settings['accessibility_settings'] = [1, 6, 7]
          settings['world_visualization'] = [8]
          break;
        // case 7:
        // case "learning_disorders": 
        //   settings['reading_mode'] = [2]
        //   settings['font_type'] = [3]
        //   settings['no_rush'] = [1]
        //   settings['accessibility_settings'] = [2]
        //   break;
        // case 8:
        // case "language_delays": //dyslexia
        //   settings['reading_mode'] = [2]
        //   settings['font_type'] = [3]
        //   settings['no_rush'] = [1]
        //   settings['accessibility_settings'] = [1,2]
        //   break;
        default:
          settings['world_visualization'] = wv
          settings['reading_mode'] = [2]
          settings['font_type'] = [0]
          settings['no_rush'] = [0]
          break;
        }

        console.log(settings, "tfygubhnij")
      return settings

    }
  },
  mutations: {},
  actions: {},
}